import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  coords: null,
  vehicleId: Math.random().toString(16).slice(10),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCoords: (state, action) => {
      state.coords = action.payload;
    },
    setVehicleId: (state, action) => {
      state.vehicleId = action.payload;
    },
    // logout: (state) => {
    //   state.user = false;
    // },
  },
});

export const { setCoords, setVehicleId } = authSlice.actions;

export default authSlice.reducer;
