import { useEffect, useState } from 'react';

const useLocationBrowser = () => {
  const [coords, setCoords] = useState(null);
  const [status, setStatus] = useState(null);

  const success = (pos) => {
    const { latitude, longitude } = pos.coords;
    setCoords({
      lat: latitude,
      lng: longitude,
    });
    setStatus('success');
  };

  const errors = (err) => {
    setStatus('error');
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  const watchLocation = () => {
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      navigator.geolocation.watchPosition(success, errors);
    }
  };

  useEffect(() => {
    watchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    coords,
    status,
  };
};

export default useLocationBrowser;
