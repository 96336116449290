import { Box, Button, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Checkbox, Form } from '../../../../../components/ui';
import { localStore } from '../../../../../utils/browser-store';
import {
  useGetPaymentsQuery,
  useUpdatePaymentsMutation,
} from '../../../services/payment-api';
import { useFetchRouteMutation } from '../../../services/route-api';
import styles from './Payments.module.css';

function Payments() {
  const { mqttInstance } = useOutletContext();
  const navigate = useNavigate();
  const { data: paymentData, isLoading } = useGetPaymentsQuery();
  const [updatePayments, { isLoading: isUpdating }] =
    useUpdatePaymentsMutation();
  const [fetchRoute, { data: routeData }] = useFetchRouteMutation({
    fixedCacheKey: 'shared-fetch-route',
  });
  const { mqttPublish } = mqttInstance;
  const { coords: start } = useSelector((state) => state.auth);


  const handleSubmit = (value) => {
    updatePayments({
      id: routeData.batchId,
      driver: routeData.driverId,
      value: value.confirmed,
      amount: paymentData?.amount,
    });

    localStore.setItem('TRACKING_MOVE_STORE', {
      ...localStore.getItem('TRACKING_MOVE_STORE'),
      payments: value,
    });

    const payload = {
      batchId: routeData.batchId,
      vehicleId: routeData.driverId,
      msg: 'avait payé',
      coords: [
        {
          lat: start[0],
          lng: start[1],
        },
        {
          lat: start[0],
          lng: start[1],
        },
      ],
      status: 'paid',
    };
    mqttPublish({ topic: 'tracking', qos: 1, payload });

    navigate(-1);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box h='full'>
      <Heading
        as='h2'
        size='lg'
        textAlign='center'
        py='10'
        textTransform='uppercase'
      >
        Supplements
      </Heading>

      <Box textAlign='center'>
        <Text fontWeight='medium' fontSize='lg' mb='10'>
          Montant du solde client à récuperer est :
        </Text>
        <Text
          color='gray.500'
          fontWeight='bold'
          fontSize='7xl'
          textTransform='uppercase'
          mb='10'
        >
          {paymentData?.amount} €
        </Text>

        <Form onSubmit={(value) => handleSubmit(value)} className={styles.form}>
          <Checkbox
            name='confirmed'
            label='Je confirme avoir récupéré le Solde'
          />
          <Box textAlign='center'>
            <Button
              type='submit'
              colorScheme='blackAlpha'
              variant='solid'
              bgColor='blackAlpha.900'
              _hover={{
                backgroundColor: 'black',
              }}
              isLoading={isUpdating}
            >
              Valider
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
}

export default Payments;
