/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L, { DivIcon } from 'leaflet';
import 'leaflet-routing-machine';
import TruckMarker from './TruckMarker';
import { RoutingMachine } from './RoutingMachine';

const leafIcon = (duration) =>
  new DivIcon({
    html: `<span>
        <b>
          ${Math.floor(duration / 60)}
          <br />
          min
        </b>
      </span>`,
    className: 'marker-custom',
    iconSize: [0, 0],
  });

const Map = ({
  driverId,
  start,
  end,
  roadTypes,
  onStart,
  onFirstRoadInfo,
  onLoaded,
}) => {
  const routingMachineRef = useRef(null);
  const [roadInfo, setRoadInfo] = useState(null);

  const directHandler = (routes) => {
    const timeEta = routes[0].summary.totalTime;
    const currentRoad = routes[0].instructions[0].road;
    onLoaded && onLoaded();
    setRoadInfo({
      timeEta,
      currentRoad,
    });
  };

  const gyroscopeHandler = (e) => {
    const truckMarker = document.querySelector('img.truck-marker');
    const compass = e.webkitCompassHeading || Math.abs(e.alpha - 360) - 180;

    if (truckMarker) {
      const translate3d = truckMarker.style.transform
        .split(' ')
        .slice(0, 3)
        .join(' ');

      truckMarker.style.transform = `${translate3d} rotate(${
        Math.round(compass) - 90
      }deg)`;
    }
  };

  const requestDeviceMotionPermission = () => {
    const isIOS =
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/);

    if (isIOS) {
      if (typeof DeviceOrientationEvent.requestPermission === 'function') {
        DeviceOrientationEvent.requestPermission()
          .then((permissionState) => {
            if (permissionState === 'granted') {
              window.addEventListener(
                'deviceorientation',
                gyroscopeHandler,
                true
              );
            }
          })
          .catch(console.error);
      }
    } else {
      window.addEventListener('deviceorientation', gyroscopeHandler, true);
    }
  };

  useEffect(() => {
    if (routingMachineRef.current) {
      routingMachineRef.current.setWaypoints([
        L.latLng(start[0], start[1]),
        L.latLng(end[0], end[1]),
      ]);
    }
  }, [start, end]);

  useEffect(() => {
    if (routingMachineRef.current) {
      if (roadTypes.length > 0) {
        routingMachineRef.current.getRouter().options.requestParameters.exclude =
          roadTypes.join(',');
        routingMachineRef.current.route();
      } else {
        delete routingMachineRef.current.getRouter().options.requestParameters
          .exclude;
        routingMachineRef.current.route();
      }
    }
  }, [roadTypes]);

  useEffect(() => {
    onFirstRoadInfo(roadInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roadInfo]);

  useEffect(() => {
    requestDeviceMotionPermission();
    onStart && onStart(requestDeviceMotionPermission);
  }, []);

  return (
    <MapContainer center={start} zoom={12} tap={false}>
      <TileLayer
        url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      <TruckMarker position={start} />
      <RoutingMachine
        ref={routingMachineRef}
        start={start}
        end={end}
        onDirect={directHandler}
      />
      <Marker icon={leafIcon(roadInfo?.timeEta)} position={end} />
    </MapContainer>
  );
};

export default Map;
