import { Box, Button, Heading } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Checkbox, Form, Input, Textarea } from '../../../../../components/ui';
import { localStore } from '../../../../../utils/browser-store';
import { useFetchRouteMutation } from '../../../services/route-api';
import { useUpdateSupplementsMutation } from '../../../services/supplement-api';
import styles from './Supplement.module.css';

const Supplement = () => {
  const { mqttInstance } = useOutletContext();
  const navigate = useNavigate();
  const [updateSupplements, { isLoading: isUpdating }] =
    useUpdateSupplementsMutation();
  const [fetchRoute, { data: routeData }] = useFetchRouteMutation({
    fixedCacheKey: 'shared-fetch-route',
  });
  const { mqttPublish } = mqttInstance;
  const { coords: start } = useSelector((state) => state.auth);

  const handleSubmit = (value) => {
    updateSupplements({
      id: routeData.batchId,
      driver: routeData.driverId,
      motif: value.reason,
      amount: parseInt(value.rising),
      checkbox: value.agreement,
    });

    localStore.setItem('TRACKING_MOVE_STORE', {
      ...localStore.getItem('TRACKING_MOVE_STORE'),
      supplements: value,
    });

    const payload = {
      batchId: routeData.batchId,
      vehicleId: routeData.driverId,
      msg: 'avait payé',
      coords: [
        {
          lat: start[0],
          lng: start[1],
        },
        {
          lat: start[0],
          lng: start[1],
        },
      ],
      status: 'completed',
    };
    mqttPublish({ topic: 'tracking', qos: 1, payload });

    navigate(-1);
  };

  return (
    <Box>
      <Heading
        as='h2'
        size='lg'
        textAlign='center'
        pt='10'
        pb='5'
        textTransform='uppercase'
      >
        Supplements
      </Heading>
      <Box w='xs' mx='auto'>
        <Form onSubmit={(value) => handleSubmit(value)} className={styles.form}>
          <Textarea
            name='reason'
            label='Motif:'
            resize='vertical'
            rows={5}
            validation={{ required: 'The field is required' }}
          />
          <Input
            type='number'
            name='rising'
            label='Montant:'
            validation={{ required: 'The field is required' }}
          />
          <Checkbox name='agreement' label='Accord du service Exploitation' />
          <Box textAlign='center' mb='2'>
            <Button
              type='submit'
              colorScheme='blackAlpha'
              variant='solid'
              bgColor='blackAlpha.900'
              _hover={{
                backgroundColor: 'black',
              }}
              isLoading={isUpdating}
            >
              Valider
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default Supplement;
