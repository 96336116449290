import { useLocation } from "react-router-dom";

const usePrevPath = () => {
  const location = useLocation();
  const currentPathArr = location.pathname.split("/");
  currentPathArr.pop();
  const prevPath = currentPathArr.join("/");
  if (prevPath === "/signature-detail") {
    return "/last-step/signatures";
  }
  return prevPath || "/";
};

export default usePrevPath;
