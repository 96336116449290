//customize the border style
const colors = {
  primary: {
    50: '#f0f1ff',
    100: '#d4d6ff',
    200: '#b9bcff',
    300: '#9ea2ff',
    400: '#8288ff',
    500: '#676eff',
    600: '#545ad1',
    700: '#4246a3',
    800: '#2f3375',
    900: '#1d1f47',
  },
  secondary: {
    50: '#f1f1f4',
    100: '#d8d8df',
    200: '#bebfcb',
    300: '#a5a6b7',
    400: '#8b8da2',
    500: '#72748e',
    600: '#5d5f74',
    700: '#494a5b',
    800: '#343541',
    900: '#202028',
  },
  tertiary: {
    50: '#e9e9ea',
    100: '#c1c1c5',
    200: '#9a9a9f',
    300: '#72727a',
    400: '#4b4b54',
    500: '#23232f',
    600: '#1d1d27',
    700: '#16161e',
    800: '#101016',
    900: '#0a0a0d',
  },
};

export default colors;
