import { Box, Container, HStack } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Page } from '../../../components/common';
import { Cart, Chat, Euro, Pencil } from '../../../components/icons';
import { localStore } from '../../../utils/browser-store';
import { ButtonCircle } from '../components/ui';

function LastStepPage() {
  const clientChecked = useSelector((state) => state.sign.sign.client);
  const moverChecked = useSelector((state) => state.sign.sign.mover);
  const signChecked = clientChecked && moverChecked ? true : false;
  const supplementChecked = localStore.getItem('TRACKING_MOVE_STORE')
    ?.supplements
    ? true
    : false;

  const paymentChecked = localStore.getItem('TRACKING_MOVE_STORE')?.payments
    ? true
    : false;

  return (
    <Page title='Last Step Page'>
      <Container px='4' maxW='container.sm'>
        <Box
          h='calc(var(--vh, 1vh) * 100 - 64px)'
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
          gap={4}
        >
          <Link to={signChecked ? '#' : '/last-step/signatures'}>
            <ButtonCircle
              label='LV Signatures'
              icon={<Pencil />}
              checked={signChecked}
              disabled={signChecked}
            />
          </Link>
          <HStack spacing='16'>
            <Link to={supplementChecked ? '#' : '/last-step/supplement'}>
              <ButtonCircle
                label='Suppléments'
                icon={<Cart />}
                checked={supplementChecked}
                disabled={supplementChecked}
              />
            </Link>
            <Link to={paymentChecked ? '#' : '/last-step/payments'}>
              <ButtonCircle
                label='Paiements'
                icon={<Euro />}
                checked={paymentChecked}
                disabled={paymentChecked}
              />
            </Link>
          </HStack>
          <Link to=''>
            <ButtonCircle
              label='Chat Exploitation'
              icon={<Chat />}
              checked={false}
              disabled
            />
          </Link>
        </Box>
      </Container>
    </Page>
  );
}

export default LastStepPage;
