import { Box, HStack } from '@chakra-ui/react';
import React from 'react';
import { Cart, Chat, Euro, Pencil } from '../../../../../components/icons';
import NavItem from './TabItem';

import styles from './TabNav.module.css';

function TabNav({ checked }) {
  return (
    <Box className={styles.tabNav}>
      <Box>
        <HStack w='full' h='full'>
          <NavItem
            label='LV Signatures'
            path='/last-step/signatures'
            icon={<Pencil />}
            disabled={false}
            checked={checked === 'signatures'}
          />
          <NavItem
            label='Paiements'
            path='/last-step/payments'
            icon={<Euro />}
            disabled={false}
            checked={checked === 'payments'}
          />
          <NavItem
            label='Suppléments'
            path='/last-step/supplement'
            icon={<Cart />}
            disabled={false}
            checked={checked === 'supplement'}
          />
          <NavItem
            label='Chat Exploitation'
            // path='/last-step/chat'
            icon={<Chat />}
            disabled={true}
            checked={checked === 'chat'}
          />
        </HStack>
      </Box>
    </Box>
  );
}

export default TabNav;
