import { Container } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Page } from '../../../components/common';
import { TabNav } from '../components/common';
import { Payments, Signatures, Supplement } from '../components/ui';

function DetailPage() {
  const params = useParams();

  const renderDetail = () => {
    switch (params.slug) {
      case 'payments':
        return <Payments />;
      case 'supplement':
        return <Supplement />;
      case 'signatures':
        return <Signatures />;
      default:
        return;
    }
  };

  return (
    <Page title='Last Step Page'>
      <Container
        maxW='container.sm'
        height='calc(var(--vh, 1vh) * 100 - 64px - 64px)'
        overflowY='auto'
      >
        {renderDetail()}
      </Container>
      <TabNav checked={params.slug} />
    </Page>
  );
}

export default DetailPage;
