import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { CheckCircle } from "../../../../../../components/icons";
import styles from "./BoxIcon.module.css";

function BoxIcon({ icon, label, checked, ...rest }) {
  return (
    <Box
      w="110px"
      h="90px"
      p={1}
      pt="5"
      borderRadius="10px"
      backgroundColor="#92C7CA"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="1"
      {...rest}
    >
      <Box className={styles.icon}>{icon}</Box>
      <HStack spacing="1">
        <Text
          fontSize="12px"
          fontWeight="500"
          color="rgba(0, 0, 0, 0.5)"
          textTransform="capitalize"
        >
          {label}
        </Text>
        {checked && <CheckCircle className={styles.checkCircleIcon} />}
      </HStack>
    </Box>
  );
}

export default BoxIcon;
