import { Box, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { CheckCircle } from '../../../../../components/icons';
import styles from './ButtonCircle.module.css';

function ButtonCircle({ label, icon, checked, ...rest }) {
  return (
    <Button variant='unstyled' height='auto' textAlign='center' {...rest}>
      <Box
        bg='white'
        boxShadow='md'
        p='7'
        rounded='full'
        display='inline-block'
        position='relative'
      >
        <Box className={styles.icon_activated}>{icon}</Box>
        <Box
          position='absolute'
          bottom='1'
          left='50%'
          transform='translate(-50%)'
        >
          {checked && <CheckCircle className={styles.checkCircleIcon} />}
        </Box>
      </Box>
      <Text fontSize='xs' fontWeight='bold' color='rgba(0,0,0, 0.5)' mt='1.5'>
        {label}
      </Text>
    </Button>
  );
}

export default ButtonCircle;
