import React, { useState } from 'react';
import { Box, Flex, Spacer, VStack } from '@chakra-ui/react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Truck, User } from '../../../../../components/icons';
import { BoxIcon } from './box-icon';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetSignQuery } from '../../../services/sign-api';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
function Signatures() {
  const { data, isLoading } = useGetSignQuery();
  const clientChecked = useSelector((state) => state.sign.sign.client);
  const moverChecked = useSelector((state) => state.sign.sign.mover);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Box pos='relative' h='full'>
      <div style={{ overflow: 'scroll', height: '100%', width: '100%' }}>
        {data && (
          <Document file={data.link} onLoadSuccess={onDocumentLoadSuccess}>
            <VStack overflowY='auto' justifyContent='center'>
              {new Array(numPages).fill(0).map((a, index)=>(<Page key={index} pageNumber={index +1} width={375} />))}
            </VStack>
          </Document>
        )}
      </div>
      <Box px='7' py='3' pos='absolute' bottom='0' width='100%' bg='white'>
        <Flex>
          <Link to='/signature-detail/client'>
            <BoxIcon
              icon={<User />}
              label='client'
              checked={clientChecked ? true : false}
            />
          </Link>
          <Spacer />
          <Link to='/signature-detail/mover'>
            <BoxIcon
              icon={<Truck />}
              label='Déménageurs'
              checked={moverChecked ? true : false}
            />
          </Link>
        </Flex>
      </Box>
    </Box>
  );
}

export default Signatures;
