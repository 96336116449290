import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SplashPage from "./pages/splash-page";

const NotFound = React.lazy(() => import("../../404"));

const AuthRoutes = () => (
  <Routes>
    <Route index path="welcome" element={<SplashPage />} />
    <Route path="404" element={<NotFound />} />
    <Route path="*" element={<Navigate to="/404" replace />} />
  </Routes>
);

export default AuthRoutes;
