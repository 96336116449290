// import { Icon } from 'leaflet';
import { Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.locatecontrol';
import { Fragment, useEffect } from 'react';

function TruckMarker({ position }) {
  const map = useMap();

  useEffect(() => {
    L.control
      .locate({
        showCompass: true,
        locateOptions: {
          enableHighAccuracy: true,
          watch: true,
        },
        flyTo: true,
        drawCircle: false,
      })
      .addTo(map)
      .start();
  }, [map]);

  return <Fragment></Fragment>;
}

export default TruckMarker;
