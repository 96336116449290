import { Box, Heading, HStack, IconButton } from '@chakra-ui/react';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { usePrevPath } from '../../../hooks';
import { ArrowBack, Close } from '../../icons';
import styles from './Header.module.css';

const Header = ({ route, batchId }) => {
  const location = useLocation();
  const prevPath = usePrevPath();

  const isShowGoBackBtn = ['last-step', 'signature-detail'].some((i) =>
    location.pathname.split('/').includes(i),
  );

  return (
    <header className={styles.header}>
      <HStack as='nav' justifyContent='space-between' w='full' h='full'>
        <HStack w='full' h='full'>
          {isShowGoBackBtn && (
            <Box>
              <IconButton
                colorScheme='blackAlpha'
                variant='solid'
                bgColor='blackAlpha.900'
                _hover={{
                  backgroundColor: 'black',
                }}
                as={NavLink}
                icon={<ArrowBack style={{ width: '48px', height: '48px' }} />}
                to={prevPath}
                w='64px'
                h='64px'
                rounded='none'
                _focus={{ outline: 'none' }}
              />
            </Box>
          )}
          <Heading as='h2' fontSize='xl' textAlign='center' flex='1' mx='5'>
            LOT {batchId} ({route})
          </Heading>
          {location.pathname === '/' && (
            <Box>
              <IconButton
                colorScheme='teal'
                as={NavLink}
                icon={<Close style={{ width: '48px', height: '48px' }} />}
                to='/last-step'
                w='64px'
                h='64px'
                rounded='none'
              />
            </Box>
          )}
        </HStack>
      </HStack>
    </header>
  );
};

export default Header;
