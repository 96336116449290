import React, { useEffect } from 'react';
import { Box, Container, Image, Text } from '@chakra-ui/react';
import { Page } from '../../../components/common';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { localStore } from '../../../utils/browser-store';

function SplashPage() {
  const { geoPermStatus } = useOutletContext();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (auth.coords) {
      const search = location.state?.from.search;
      const id = new URLSearchParams(search).get('id');
      if (id) {
        localStore.setItem('TRACKING_MOVE_STORE', {
          ...localStore.getItem('TRACKING_MOVE_STORE'),
          requestId: id,
        });
      }

      const from = location.state?.from?.pathname || '/';
      navigate(from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.coords]);

  return (
    <Page title='Splash Page'>
      <Container px='4' maxW='container.sm'>
        <Box
          h='100vh'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          position='relative'
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-end'
            transform='translate(0px, -50px)'
          >
            <Image
              boxSize='full'
              objectFit='contain'
              src='/logo.png'
              alt='logo'
            />
            <Text fontSize='md' as='samp' mr='7px'>
              Turn by turn mobile
            </Text>
          </Box>
          <Box position='absolute' bottom='0' mb='150px'>
            {geoPermStatus === 'error' && (
              <Text
                textAlign='center'
                color='tomato'
                fontSize='md'
                fontWeight='bold'
              >
                Votre GPS n’est <br />
                pas activé
              </Text>
            )}
          </Box>
          <Text position='absolute' bottom='0' fontSize='lg' mb='14px'>
            V 1.0
          </Text>
        </Box>
      </Container>
    </Page>
  );
}

export default SplashPage;
