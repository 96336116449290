import { Box, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import cn from 'classnames';
import styles from './TabItem.module.css';

function TabItem({ label, icon, path = '', disabled, checked }) {
  const boxItemClassName = cn(styles.boxItem, {
    [styles.checked]: checked,
  });
  const iconClassName = cn(styles.icon, {
    [styles.icon_disabled]: disabled,
  });

  return (
    <Link
      as={ReactLink}
      to={path}
      className={boxItemClassName}
      _hover={{ textDecoration: 'none' }}
      _focus={{ outline: 'none' }}
    >
      <Box className={iconClassName}>{icon}</Box>
      <Text
        fontSize='10px'
        mb='3px'
        fontWeight='bold'
        color={disabled ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.5)'}
      >
        {label}
      </Text>
    </Link>
  );
}

export default TabItem;
