/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect, useRef, useState } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { PrimaryLayout, SecondaryLayout } from './layouts';
import Home from './modules/home/pages';
import AuthRoutes from './modules/auth/routes';
import LastStepPage from './modules/home/pages/last-step-page';
import { GuardRoute } from './router';
import DetailPage from './modules/home/pages/detail-page';
import nosleep from 'nosleep.js';
import SignatureDetailPage from './modules/home/pages/signature-detail-page';
import { localStore } from './utils/browser-store';
import { useMqtt } from './modules/home/hooks';
import { useFetchRouteMutation } from './modules/home/services/route-api';
import { setCoords } from './modules/auth/services/authSlice';
import { useLocationBrowser } from './hooks';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

function App() {
  const noSleep = new nosleep();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const mqttInstance = useMqtt(process.env.REACT_APP_BROKER_HOST, {
    clientId: id,
  });
  const { connectStatus, isSubed, mqttSub, mqttPublish } = mqttInstance;
  const {
    connectStatus: connectStatusA,
    isSubed: isSubedA,
    payload: payloadA,
    mqttSub: mqttSubA,
  } = useMqtt(process.env.REACT_APP_BROKER_HOST, {
    clientId: uuidv4(),
  });
  const isTruckStarted = useSelector((state) => state.home.isTruckStarted);
  const [fetchRoute, { isSuccess, data }] = useFetchRouteMutation({
    fixedCacheKey: 'shared-fetch-route',
  });
  const { coords, status } = useLocationBrowser();
  const dispatch = useDispatch();
  const isDashboardConnected = payloadA?.message?.status === 'connected';
  const [count, setCount] = useState(0);
  const timeIntervalId = useRef(null);

  if (id) {
    localStore.setItem('TRACKING_MOVE_STORE', {
      ...localStore.getItem('TRACKING_MOVE_STORE'),
      requestId: id,
    });
  }

  useEffect(() => {
    if (coords) {
      dispatch(setCoords([coords.lat, coords.lng]));
    }
  }, [coords]);

  useEffect(() => {
    fetchRoute();
  }, []);

  useEffect(() => {
    if (connectStatusA === 'connected') {
      mqttSubA({ topic: 'dashboard', qos: 1 });
    }
  }, [connectStatusA]);

  useEffect(() => {
    if (isSuccess && connectStatus === 'connected') {
      mqttSub({ topic: 'tracking', qos: 1 });
    }
  }, [connectStatus, isSuccess]);

  useEffect(() => {
    if (isSubed && isSuccess) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        window.addEventListener('visibilitychange', function (e) {
          if (document.visibilityState === 'hidden') {
            const payload = {
              batchId: data.batchId,
              vehicleId: data.driverId,
              msg: 'Mis hors ligne du',
              coords: [coords, coords],
              status: 'disconnected',
            };

            mqttPublish({ topic: 'tracking', qos: 1, payload });
            e.preventDefault();
          }
          if (document.visibilityState === 'visible') {
            const payload = {
              batchId: data.batchId,
              vehicleId: data.driverId,
              msg: 'Mis en ligne du',
              coords: [coords, coords],
              status: 'connected',
            };

            mqttPublish({ topic: 'tracking', qos: 1, payload });
          }
        });
      }

      window.addEventListener('beforeunload', function (e) {
        const payload = {
          batchId: data.batchId,
          vehicleId: data.driverId,
          msg: 'Mis hors ligne du',
          coords: [coords, coords],
          status: 'disconnected',
        };

        mqttPublish({ topic: 'tracking', qos: 1, payload });
        e.preventDefault();
      });
    }
  }, [isSubed, isSuccess]);

  useEffect(() => {
    if (isSubed && coords && count === 30) {
      setCount(0);
      const payload = {
        batchId: data.batchId,
        vehicleId: data.driverId,
        coords: [coords, coords],
        status: 'no-road',
      };

      if (isTruckStarted) {
        payload.status = 'routing';
        payload.coords = [coords, { lat: data.coords[0], lng: data.coords[1] }];
      }
      mqttPublish({ topic: 'tracking', qos: 2, payload });
    }
  }, [isSubed, coords, count]);

  useEffect(() => {
    if (isSubed && status === 'success') {
      const payload = {
        batchId: data.batchId,
        vehicleId: data.driverId,
        msg: 'Mis en ligne du',
        coords: [coords, coords],
        status: 'connected',
      };

      mqttPublish({ topic: 'tracking', qos: 1, payload });
    }
  }, [isSubed, status]);

  useEffect(() => {
    if (isSubed && isSubedA && isDashboardConnected && status === 'success') {
      const payload1 = {
        batchId: data.batchId,
        vehicleId: data.driverId,
        msg: 'Mis en ligne du',
        coords: [coords, coords],
        status: 'connected',
      };
      mqttPublish({ topic: 'tracking', qos: 1, payload: payload1 });

      let payload2 = {
        batchId: data.batchId,
        vehicleId: data.driverId,
        coords: [coords, coords],
        status: 'no-road',
      };

      if (isTruckStarted) {
        payload2.status = 'routing';
        payload2.coords = [
          coords,
          { lat: data.coords[0], lng: data.coords[1] },
        ];
      }
      mqttPublish({ topic: 'tracking', qos: 2, payload: payload2 });
    }
  }, [isSubed, isSubedA, isDashboardConnected, status]);

  useEffect(() => {
    function enableNoSleep() {
      noSleep.enable();
      document.removeEventListener('touchstart', enableNoSleep, false);
    }

    document.addEventListener('touchstart', enableNoSleep, false);
  }, []);

  useEffect(() => {
    timeIntervalId.current = setInterval(() => {
      setCount((prev) => prev + 1);
    }, 1000);

    return () => {
      clearInterval(timeIntervalId.current);
    };
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route element={<PrimaryLayout mqttInstance={mqttInstance} />}>
          <Route
            path='/'
            element={
              <GuardRoute>
                <Home />
              </GuardRoute>
            }
          />
          <Route
            path='/last-step'
            element={
              <GuardRoute>
                <LastStepPage />
              </GuardRoute>
            }
          />
          <Route
            path='/last-step/:slug'
            element={
              <GuardRoute>
                <DetailPage />
              </GuardRoute>
            }
          />
          <Route
            path='/signature-detail/:slug'
            element={
              <GuardRoute>
                <SignatureDetailPage />
              </GuardRoute>
            }
          />
        </Route>
        <Route element={<SecondaryLayout geoPermStatus={status} />}>
          <Route index path='/*' element={<AuthRoutes />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
