import { createSlice } from '@reduxjs/toolkit';
import { localStore } from '../../../utils/browser-store';

const initialState = {
  sign: {
    client: localStore.getItem('TRACKING_MOVE_STORE')?.signClient || null,
    mover: localStore.getItem('TRACKING_MOVE_STORE')?.signMover || null,
  },
};

export const signSlice = createSlice({
  name: 'sign',
  initialState,
  reducers: {
    setSignClient: (state, action) => {
      state.sign.client = action.payload;
      localStore.setItem('TRACKING_MOVE_STORE', {
        ...localStore.getItem('TRACKING_MOVE_STORE'),
        signClient: action.payload,
      });
    },
    setSignMover: (state, action) => {
      state.sign.mover = action.payload;
      localStore.setItem('TRACKING_MOVE_STORE', {
        ...localStore.getItem('TRACKING_MOVE_STORE'),
        signMover: action.payload,
      });
    },
  },
});

export const { setSignClient, setSignMover } = signSlice.actions;

export default signSlice.reducer;
