import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { localStore } from "../../../utils/browser-store";

const supplementApi = createApi({
  reducerPath: "supplementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_TRACKING_MOVE_BASE_URL}/v1/tracking/mobile/supplements`,
    prepareHeaders: (headers) => {
      const { requestId } = localStore.getItem("TRACKING_MOVE_STORE");
      headers.set("X-Request-ID", requestId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    updateSupplements: build.mutation({
      query: (obj) => ({
        url: "",
        method: "POST",
        body: obj,
      }),
    }),
  }),
});

export default supplementApi;

export const { useUpdateSupplementsMutation } = supplementApi;
