/* eslint-disable no-unused-vars */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Truck, User } from '../../../components/icons';
import { BoxIcon } from '../components/ui/signatures/box-icon';
import DrawingArea from '../components/ui/signatures/DrawingArea';
import { setSignClient, setSignMover } from '../services/sign-slice';
import { useGetSignQuery, usePostSignMutation } from '../services/sign-api';
import { useFetchRouteMutation } from '../services/route-api';

function SignatureDetailPage() {
  const { mqttInstance } = useOutletContext();
  const params = useParams();
  const isClient = params.slug === 'client';
  const [comment, setComment] = useState('');
  const [sign, setSign] = useState(null);
  const [showError, setShowError] = useState(false);
  const clearCbRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: signData, isLoading } = useGetSignQuery();
  const [postSign, { isLoading: isSubmitting }] = usePostSignMutation();
  const { mqttPublish } = mqttInstance;
  const [fetchRoute, { data: routeData }] = useFetchRouteMutation({
    fixedCacheKey: 'shared-fetch-route',
  });
  const { coords: start } = useSelector((state) => state.auth);

  function handleSubmit() {
    if (comment && sign) {
      const obj = { comments: comment, sign: sign };

      if (isClient) {
        postSign({ ...obj, id: routeData.batchId, driver: routeData.driverId });
        dispatch(setSignClient({ ...obj }));
      } else {
        postSign({ ...obj, id: routeData.batchId, driver: routeData.driverId });
        dispatch(setSignMover(obj));
      }

      const payload = {
        batchId: routeData.batchId,
        vehicleId: routeData.driverId,
        msg: 'a fait signé la LV',
        coords: [
          {
            lat: start[0],
            lng: start[1],
          },
          {
            lat: start[0],
            lng: start[1],
          },
        ],
        status: 'lvSigned',
      };
      mqttPublish({ topic: 'tracking', qos: 1, payload });

      setComment('');
      clearCbRef.current();
      navigate(-1);
    } else {
      setShowError(true);
    }
  }

  return (
    <Box
      w='100%'
      minHeight='calc(var(--vh, 1vh) * 100 - 64px)'
      pt='2'
      pb='10'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
    >
      {isClient ? (
        <BoxIcon icon={<User />} label='client' />
      ) : (
        <BoxIcon icon={<Truck />} label='Deménogeurs' />
      )}
      {showError && (
        <Alert status='error' pos='absolute' top='64px'>
          <AlertIcon />
          <AlertTitle mr={2}>Error</AlertTitle>
          <AlertDescription>Fill all</AlertDescription>
          <CloseButton
            position='absolute'
            right='8px'
            top='8px'
            onClick={() => setShowError(false)}
          />
        </Alert>
      )}
      <Box w='360px' borderWidth='1px' mb='1' pos='relative'>
        <Text
          fontWeight='semibold'
          pos='absolute'
          top='0'
          left='0'
          p='1'
          fontSize='sm'
          textTransform='uppercase'
        >
          {isClient ? 'COMMENTAIRE CLIENT :' : 'COMMENTAIRE DEMENAGEUR:'}
        </Text>
        <Textarea
          mt='7'
          border='none'
          rows='6'
          focusBorderColor='inherit'
          name='comment'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Box>
      <Box pos='relative'>
        <Text
          fontWeight='semibold'
          pos='absolute'
          top='0'
          left='0'
          p='1'
          fontSize='sm'
          textTransform='uppercase'
        >
          {isClient ? ' SIGNATURE DU CLIENT :' : 'SIGNATURE DU DEMENAGEUR:'}
        </Text>
        <DrawingArea
          onExport={(value) => setSign(value)}
          onClear={(clear) => (clearCbRef.current = clear)}
        />
      </Box>
      <Box textAlign='center'>
        <Button
          type='submit'
          colorScheme='blackAlpha'
          variant='solid'
          bgColor='blackAlpha.900'
          _hover={{
            backgroundColor: 'black',
          }}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          Valider
        </Button>
      </Box>
    </Box>
  );
}

export default SignatureDetailPage;
