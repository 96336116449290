import React, { Fragment, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../../components/common';
import { useFetchRouteMutation } from '../../modules/home/services/route-api';
import styles from './PrimaryLayout.module.css';

const PrimaryLayout = ({ mqttInstance }) => {
  const [fetchRoute, { isSuccess, data }] = useFetchRouteMutation({
    fixedCacheKey: 'shared-fetch-route',
  });
  const [headerState, setHeaderState] = useState({});

  useEffect(() => {
    if (data) {
      setHeaderState({
        route: data.route,
        batchId: data.batchId,
      });
    }
  }, [data]);

  return (
    <Fragment>
      <Header route={headerState.route} batchId={headerState.batchId} />
      <main className={styles.main}>
        <Outlet context={{ mqttInstance }} />
      </main>
    </Fragment>
  );
};

export default PrimaryLayout;
