import { useEffect, useState } from 'react';
import mqtt from 'mqtt/dist/mqtt';

const useMqtt = (host, mqttOption) => {
  const [client, setClient] = useState(null);
  const [isSubed, setIsSub] = useState(false);
  const [payload, setPayload] = useState({});
  const [connectStatus, setConnectStatus] = useState('Connect');

  const mqttConnect = (host, mqttOption) => {
    setConnectStatus('connecting');
    setClient(
      mqtt.connect(host, {
        keepalive: 30,
        protocolId: 'MQTT',
        protocolVersion: 4,
        clean: true,
        reconnectPeriod: 1000,
        connectTimeout: 30 * 1000,
        will: {
          topic: 'WillMsg',
          payload: 'Connection Closed abnormally..!',
          qos: 2,
          retain: false,
        },
        rejectUnauthorized: false,
        ...mqttOption,
      }),
    );
  };

  const mqttDisconnect = () => {
    if (client) {
      client.end(() => {
        setConnectStatus('disconnected');
      });
    }
  };

  const mqttPublish = (context) => {
    if (client && isSubed) {
      const { topic, qos, payload } = context;
      client.publish(topic, JSON.stringify(payload), { qos }, (error) => {
        if (error) {
          console.log('Publish error: ', error);
        }
      });
    }
  };

  const mqttSub = (subscription) => {
    if (client && connectStatus === 'connected') {
      const { topic, qos } = subscription;
      client.subscribe(topic, { qos }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error);
          return;
        }
        setIsSub(true);
      });
    }
  };

  const mqttUnSub = (subscription) => {
    if (client) {
      const { topic } = subscription;
      client.unsubscribe(topic, (error) => {
        if (error) {
          console.log('Unsubscribe error', error);
          return;
        }
        setIsSub(false);
      });
    }
  };

  useEffect(() => {
    mqttConnect(host, mqttOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        setConnectStatus('connected');
      });
      client.on('error', (err) => {
        console.error('Connection error: ', err);
        client.end();
      });
      client.on('reconnect', () => {
        setConnectStatus('reconnecting');
      });
      client.on('message', (topic, message) => {
        const payload = { topic, message: JSON.parse(message.toString()) };
        setPayload(payload);
      });
    }
  }, [client]);

  return {
    client,
    isSubed,
    connectStatus,
    payload,
    mqttPublish,
    mqttSub,
    mqttUnSub,
    mqttDisconnect,
  };
};

export default useMqtt;
