import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';
import 'leaflet-routing-machine';

const RoutineMachineLayer = (props) => {
  const { start, end, onDirect } = props;

  const instance = L.Routing.control({
    waypoints: [L.latLng(start[0], start[1]), L.latLng(end[0], end[1])],
    lineOptions: {
      styles: [{ color: '#6FA1EC', weight: 4 }],
    },
    show: false,
    addWaypoints: false,
    draggableWaypoints: false,
    router: L.Routing.mapbox(process.env.REACT_APP_MAPBOX_ACCESS_TOKEN),
    createMarker: () => null,
  });

  instance.on('routesfound', (e) => {
    onDirect(e.routes);
  });

  return instance;
};

export const RoutingMachine = createControlComponent(RoutineMachineLayer);
