import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { localStore } from '../../../utils/browser-store';
import { routeDataMapper } from '../utils/dataMapper';

const api = createApi({
  reducerPath: 'routeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_TRACKING_MOVE_BASE_URL}/v1/tracking/mobile/route`,
    prepareHeaders: (headers) => {
      const { requestId } = localStore.getItem('TRACKING_MOVE_STORE');
      headers.set('X-Request-ID', requestId);
      return headers;
    },
  }),
  tagTypes: ['Route'],
  endpoints: (build) => ({
    fetchRoute: build.mutation({
      query: () => ({
        url: '',
        method: 'POST',
      }),
      transformResponse: (res) => routeDataMapper(res.data),
    }),
  }),
});

export default api;

export const { useFetchRouteMutation } = api;
