import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import authReducer from '../modules/auth/services/authSlice';
import signReducer from '../modules/home/services/sign-slice';
import homeReducer from '../modules/home/services/home-slice';
import {
  routeApi,
  signApi,
  paymentApi,
  supplementApi,
} from '../modules/home/services';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    sign: signReducer,
    home: homeReducer,
    [routeApi.reducerPath]: routeApi.reducer,
    [signApi.reducerPath]: signApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [supplementApi.reducerPath]: supplementApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      paymentApi.middleware,
      routeApi.middleware,
      signApi.middleware,
      supplementApi.middleware,
    ),
});

setupListeners(store.dispatch);
