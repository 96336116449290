function getItem(name) {
  const itemStr = localStorage.getItem(name);
  return itemStr ? JSON.parse(itemStr) : null;
}

function setItem(name, value) {
  localStorage.setItem(name, JSON.stringify(value));
}

function removeItem(name) {
  localStorage.removeItem(name);
}

const localStore = {
  getItem,
  setItem,
  removeItem,
};

export default localStore;
