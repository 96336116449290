import React from 'react';
import { useForm } from 'react-hook-form';

const Form = ({ defaultValues, children, onSubmit, ...rest }) => {
  const methods = useForm({ defaultValues });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...rest}>
      {React.Children.map(children, (child) => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register,
                errors,
                key: child.props.name,
              },
            })
          : child;
      })}
    </form>
  );
};

export default Form;
