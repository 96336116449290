/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Page } from '../../../components/common';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from '../components/ui';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useFetchRouteMutation } from '../services/route-api';
import { setTruckStarted } from '../services/home-slice';

const hideShadowFocusStr = `
  > span:first-of-type {
    box-shadow: unset;
  }
`;

const Home = () => {
  const { mqttInstance } = useOutletContext();
  const { mqttPublish, isSubed } = mqttInstance;
  const navigate = useNavigate();
  const isTruckStarted = useSelector((state) => state.home.isTruckStarted);
  const dispatch = useDispatch();
  const [roadTypes, setRoadTypes] = useState([]);
  const { coords: currentCoords } = useSelector((state) => state.auth);

  const cbRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fetchRoute, { isLoading, data: routeData }] = useFetchRouteMutation({
    fixedCacheKey: 'shared-fetch-route',
  });
  const firstRoadRef = useRef({});
  const start = currentCoords;
  const end = routeData?.coords || [48.8566, 2.3522];

  function changeStatus() {
    if (isTruckStarted === false) {
      dispatch(setTruckStarted(true));
      cbRef.current();
      const payload = {
        batchId: routeData?.batchId,
        vehicleId: routeData?.driverId,
        msg: `est arrivé au <span style="color: white;">${firstRoadRef.current.currentRoad}</span>`,
        coords: [
          {
            lat: start[0],
            lng: start[1],
          },
          {
            lat: end[0],
            lng: end[1],
          },
        ],
        timeEta: firstRoadRef.current.timeEta,
        status: 'loading',
      };
      mqttPublish({ topic: 'tracking', qos: 1, payload });
    } else {
      dispatch(setTruckStarted(false));
      const payload = {
        batchId: routeData?.batchId,
        vehicleId: routeData?.driverId,
        msg: `est arrivé au <span style="color: white;">${firstRoadRef.current.currentRoad}</span>`,
        coords: [
          {
            lat: start[0],
            lng: start[1],
          },
          {
            lat: end[0],
            lng: end[1],
          },
        ],
        status: 'transport',
      };
      mqttPublish({ topic: 'tracking', qos: 1, payload });

      navigate('/last-step');
    }
  }

  const roadTypesHandler = (value) => {
    setRoadTypes(value);
    onOpen();
  };

  useEffect(() => {
    if (isLoading) {
      onOpen();
    } else {
      onClose();
    }
  }, [isLoading]);

  return (
    <Page title='Home page'>
      <Modal isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent bg='transparent'>
          <Spinner size='xl' color='white' mx='auto' />
        </ModalContent>
      </Modal>
      <section>
        <Container maxW='container.sm'>
          <Box pos='relative'>
            <Box h='calc(var(--vh, 1vh) * 100 - 64px - 64px)'>
              <Map
                driverId={routeData?.driverId}
                start={start}
                end={end}
                roadTypes={roadTypes}
                onStart={(cb) => (cbRef.current = cb)}
                onFirstRoadInfo={(roadInfo) =>
                  (firstRoadRef.current = roadInfo)
                }
                onLoaded={() => onClose()}
              />
            </Box>
            <Box
              pos='absolute'
              top='0'
              right='0'
              p='2'
              bg='transparent'
              zIndex='999'
            >
              <CheckboxGroup
                colorScheme='teal'
                value={roadTypes}
                onChange={roadTypesHandler}
              >
                <VStack spacing='1' alignItems='start'>
                  <Checkbox
                    borderColor='gray.400'
                    css={hideShadowFocusStr}
                    value='toll'
                  >
                    Sans péage
                  </Checkbox>
                  <Checkbox
                    borderColor='gray.400'
                    css={hideShadowFocusStr}
                    value='motorway'
                  >
                    Sans autoroute
                  </Checkbox>
                </VStack>
              </CheckboxGroup>
            </Box>
          </Box>
          <HStack h='64px'>
            <Button
              w='full'
              h='full'
              colorScheme={isTruckStarted ? 'orange' : 'teal'}
              rounded='none'
              fontSize='4xl'
              fontWeight='bold'
              onClick={() => changeStatus()}
            >
              {isTruckStarted ? 'STOP' : 'GO!'}
            </Button>
          </HStack>
        </Container>
      </section>
    </Page>
  );
};

export default Home;
