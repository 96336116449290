import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { localStore } from '../../../utils/browser-store';

const api = createApi({
  reducerPath: 'routeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_TRACKING_MOVE_BASE_URL}/v1/tracking/mobile/sign`,
    prepareHeaders: (headers) => {
      const { requestId } = localStore.getItem('TRACKING_MOVE_STORE');
      headers.set('X-Request-ID', requestId);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getSign: build.query({
      query: () => '',
      transformResponse: (res) => res.data,
    }),
    postSign: build.mutation({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export default api;

export const { useGetSignQuery, usePostSignMutation } = api;
