import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { localStore } from '../../../utils/browser-store';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_TRACKING_MOVE_BASE_URL}/v1/tracking/mobile/payments`,
    prepareHeaders: (headers) => {
      const { requestId } = localStore.getItem('TRACKING_MOVE_STORE');
      headers.set('X-Request-ID', requestId);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPayments: builder.query({
      query: () => '',
      transformResponse: (res) => res.data,
    }),
    updatePayments: builder.mutation({
      query: (obj) => ({
        url: '',
        method: 'POST',
        body: obj,
      }),
    }),
  }),
});

export default paymentApi;

export const { useGetPaymentsQuery, useUpdatePaymentsMutation } = paymentApi;
