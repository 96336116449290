const Container = {
  baseStyle: {
    w: '100%',
    mx: 'auto',
    maxW: '60ch',
    // px: '1.25rem',
    px: '0',
  },
};

export default Container;
