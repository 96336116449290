import { React, useEffect } from 'react';
import { Stage, Layer, Line } from 'react-konva';
import { useState, useRef } from 'react';
import { VStack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';

const DrawingArea = ({ onExport, onClear }) => {
  const [lines, setLines] = useState([]);
  const isDrawing = useRef(false);
  const stageRef = useRef(null);

  useEffect(() => {
    if (lines.length > 0) {
      const uri = stageRef.current.toDataURL();
      onExport && onExport(uri);
    }
  }, [onExport, lines]);

  useEffect(() => {
    onClear && onClear(() => setLines([]));
  }, [onClear]);

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];

    if (lastLine) {
      // add point
      lastLine.points = lastLine.points.concat([point.x, point.y]);

      // replace last
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
    }
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  return (
    <VStack
      borderWidth='1px'
      pos='relative'
      alignItems='center'
      justifyContent='center'
    >
      <Button
        pos='absolute'
        top='0'
        right='0'
        zIndex='10'
        variant='ghost'
        size='sm'
        onClick={() => setLines([])}
      >
        Clear
      </Button>

      <Stage
        width={360}
        height={180}
        ref={stageRef}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke='#ccc'
              strokeWidth={1}
              tension={0.5}
              lineCap='round'
              globalCompositeOperation='source-over'
            />
          ))}
        </Layer>
      </Stage>
    </VStack>
  );
};

export default DrawingArea;
